<template>
  <v-col class="ma-0 pa-0">
    <v-data-table v-model="selected" :headers="headers" :items="contracts" show-select
                  :items-per-page="5" item-key="id" mobile-breakpoint="0" style="backgroud-color: transparent">
      <template v-slot:item.level="props">
        <v-row class="mt-2">
          <div v-if="props.item.level === 'diamond'">
            <diamond-icon/>
          </div>
          <div v-else>
            <v-icon size="20" :color="props.item.level" class="mr-2" >mdi-hexagon</v-icon>
          </div>
          <a target="_blank" :href="`https://blog.naver.com/${props.item.outerId}`" style="color: rgba(0,0,0,.87);">{{ props.item.username }}</a><br />
          <span class="ml-6" :class="props.item.type === 'insta' ? 'pink--text text--darken-2': 'green--text'">{{ props.item.type === 'insta' ? '인스타' : '네이버' }}</span>
        </v-row>
      </template>
      <template v-slot:item.bloggerInfo="props">
        <v-row @click="influencerDetailDlg(props.item?.Influencer)" style="color:#4770BD; padding-bottom:4px; font-size:13px;">
          {{props.item?.Influencer?.account}}
          <v-icon small color="#4770BD" class="ml-1">mdi-text-box-search-outline</v-icon>
        </v-row>
        <v-row v-if="props.item?.Influencer">
          <v-chip outlined x-small color="#34CD89"
                  class="ma-0 mr-1 mb-1"
                  v-for="item in Object.keys(props.item?.Influencer?.categories).sort(function (a, b) { return props.item?.Influencer?.categories[b] - props.item?.Influencer?.categories[a]; }).slice(0, 2)" :key="item">
            {{item + ' ' + Math.round(props.item?.Influencer?.categories[item]) + '%'}}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:item.bloggerRank="props">
        {{ props.item?.Influencer?.rank | numFormat}}
      </template>
      <template v-slot:item.bloggerVisitor="props">
        {{ props.item?.Influencer?.visitor | numFormat}}
      </template>
      <template v-slot:footer>
        <v-row class="mt-2" style="position: absolute">
          <v-btn v-if="company.type==='A' && $moment().startOf('day').diff($moment(curAdsPayment.adsStart || company.naver.payInfo_startPaidAt || company.naver.startDate).startOf('day'), 'days') < (company.periodApply + 1)"
                 color="grey" class="pa-1 caption" outlined flat tile disabled>
            {{$moment(curAdsPayment.adsStart || company.naver.payInfo_startPaidAt || company.naver.startDate).startOf('day').add(company.periodApply + 1, 'd').format('YYYY.MM.DD')}}부터 수락 가능
          </v-btn>
          <v-btn v-else-if="company.type==='A' && $moment().startOf('day').diff($moment(curAdsPayment.adsStart || company.naver.payInfo_startPaidAt || company.naver.startDate).startOf('day'), 'days') < (company.periodApply + company.periodAccept + 1) && !accepted"
                 @click="showAcceptDlg=true" color="primary" :outlined="!(selected.length > 0)" flat tile>
            {{selected.length > 0 ? (selected.length + '/' + ((curAdsPayment.number || company.naver.number) - prevSelectedUsers.length)) : ''}}수락하기
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
    <v-row justify="center">
      <InfluencerDetail v-model="openInfluencerDetail" :openInfluencer="openInfluencer">
      </InfluencerDetail>
    </v-row>
    <v-row justify="center" align="center">
      <v-dialog v-model="showAcceptDlg" content-class="white-two" max-width="522px">
        <v-card class="pa-4">
          <v-card-title>
            <div>
              <v-icon color="default" @click="showAcceptDlg = false">close</v-icon><br />
              <h2 class="mt-2 font-weight-bold headline">
                수락 완료하시겠습니까?
              </h2>
            </div>
          </v-card-title>
          <v-card-text class="font-size:14px;color:#484848;">
            <v-row class="mt-2">
              수락하면 캠페인이 시작되며, 추가로 인원을 선택하실 수 없습니다.
            </v-row>
          </v-card-text>
          <v-card-actions style="padding: 16px 0 0 0">
            <v-spacer></v-spacer>
            <v-btn tile class="rouge white-two--text" @click="accept();" width="80">수락</v-btn>
            <v-btn color="rouge" tile outlined flat @click="showAcceptDlg = false" width="80">취소</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-col>
</template>

<script>
import companyMixin from '@/components/shared/companyMixin';
import InfluencerDetail from "@/components/campaign/dialog/InfluencerDetail.vue";

export default {
  name: 'Apply',
  components: {
    diamondIcon: () => import('@/assets/img/regads/diamond.svg'),
    InfluencerDetail
  },
  mixins: [companyMixin],
  props: ['contracts', 'option', 'tap', 'adsPayments'],
  data() {
    return {
      headers: [
        { text: '등급 ∙ 회원명', value: 'level', sortable: false, width: 120 },
        { text: '블로거 정보', align: 'left', value: 'bloggerInfo', sortable: false, width: 240},
        { text: '국내 순위', align: 'left', value: 'bloggerRank', sortable: false},
        { text: '일방문자', value: 'bloggerVisitor', sortable: false },],
      selected: [],
      contract: {},
      rowsPerPage: 5,
      openInfluencer: {},
      openInfluencerDetail: false,
      applyList: [],
      showAcceptDlg: false
    };
  },
  computed: {
    accepted: function () {
      return (this.contracts.filter((contract) => {
        let date = this.$moment(new Date(contract.appliedAt)).tz("Asia/Seoul").format("YYYY-MM-DD");
        return this.$moment(date).tz("Asia/Seoul").isSameOrAfter(this.curAdsPayment.adsStart || this.company.naver.payInfo_startPaidAt || this.company.naver.startDate, 'day')
            && (contract.currentState >= 0 || contract.currentState === -3);
      }) || []).length === (this.curAdsPayment.number || this.company.naver.number);
    },
    prevSelectedUsers: function () {
      return (this.contracts.filter((contract) => {
        let date = this.$moment(new Date(contract.appliedAt)).tz("Asia/Seoul").format("YYYY-MM-DD");

        console.log(contract)
        return this.$moment(date).tz("Asia/Seoul").isSameOrAfter(this.curAdsPayment.adsStart || this.company.naver.payInfo_startPaidAt || this.company.naver.startDate, 'day')
            && !!contract.selectedAt
      }) || []);
    },
    curAdsPayment: function () {
      return this.adsPayments.find((adsPayment) => {
        return this.$moment().isBetween(this.$moment(adsPayment.adsStart), this.$moment(adsPayment.nextPaidAt), 'days', '[)');
      });
    }
  },
  methods: {
    async influencerDetailDlg(item) {
      this.openInfluencerDetail = true;
      this.openInfluencer = item;
    },
    async accept() {
      this.showAcceptDlg = false; 
      const currentPaycard = this.curAdsPayment;
      if(!currentPaycard) {
        alert('결제정보를 불러오는데 실패했습니다.');
        return;
      }
      const { adsStart, lastPaidAt } = currentPaycard;
      const accepted = this.contracts.reduce( (acc,contract) => { 
        if(this.$moment(contract.appliedAt).isBetween(this.$moment(adsStart), this.$moment(lastPaidAt), 'days', "[)")){
          if(contract.currentState !== -2 && contract.currentState !== -1) acc += 1; 
        }
        return acc;
      }, 0);

      if(this.selected.length > currentPaycard.number - accepted ){
        alert('선정 가능 인원보다 많은 수의 블로거를 선택하셨습니다.');
        return;
      } 

      await Promise.all(this.selected.map((contract) => {
        this.axios.put('/contract?id=' + (contract.id || contract.rKey), {
          user: contract.user,
          company: contract.company,
          currentState: (contract.first === '제품') ? 0 : -3,
          ...(contract.first === '제품') && { date0: new Date() },
          selectedAt : new Date(),
          point: this.company[contract.level + 'Point'] || this.company.diamondPoint
        });
      }));

      setTimeout(() => {
        this.$emit('complete');
      }, 2000);
    },
  },
  watch: {
  },
  created() {
    const selectedUser = this.contracts.filter(c => !!c.selectedAt);
    this.selected = selectedUser;
  },
};
</script>

<style scoped>
::v-deep .v-expansion-panel__header {
  padding: 0px;
}

::v-deep .v-text-field--outline.v-text-field--single-line input {
  margin-top: 0;
}

::v-deep .v-text-field--outline.v-text-field--single-line .v-input__slot {
  min-height: 40px;
  border-radius: 4px;
  border: solid 1px #c2c2c2;
  border: solid 1px var(--pinkish-grey-color);
}

::v-deep .v-label {
  font-size: 14px;
}

.circle {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
</style>